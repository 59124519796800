import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form, Modal } from 'react-bootstrap';
import { updateFranchiseVATSettings } from '@app/services/franchise';

const UpdateVATSettingsModal = (props) => {

    const [isLoading, setLoading] = useState(false);
    const [vatTypes, setVATTypes] = useState([])
    
    useEffect(() => {
        if(props?.payload?.enabled_student_subject_vat){
            setVATTypes([{value: 'inclusive' , name:'Inclusive', isDisabled: false, isChecked: false}, {value: 'exclusive', name:'Exclusive', isDisabled: false, isChecked: false}, {value: 'not_applicable', name:'N/A', isDisabled: true, isChecked: false}])
        }else{
            setVATTypes([{value: 'inclusive' , name:'Inclusive', isDisabled: true, isChecked: false}, {value: 'exclusive', name:'Exclusive', isDisabled: true, isChecked: false}, {value: 'not_applicable', name:'N/A', isDisabled: false, isChecked: true}])
        }
    }, [props?.payload]);

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            enabled_student_subject_vat: props?.payload?.enabled_student_subject_vat ?? false,
            vat_status: props?.payload?.vat_status ?? '',
            enabled_student_fee_credit_vat: props?.payload?.enabled_student_fee_credit_vat ?? false,
            standard_vat_percentage: props?.payload?.standard_vat_percentage ?? '',
        },
        validationSchema: Yup.object({
            standard_vat_percentage: Yup.number().when(['enabled_student_subject_vat', 'enabled_student_fee_credit_vat'], {
                is: (enabled_student_subject_vat: boolean, enabled_student_fee_credit_vat: boolean) => ( enabled_student_subject_vat || enabled_student_fee_credit_vat),
                then: Yup.number().required('Please enter VAT percentage')
                .min(0.1, "VAT percentage must be greater than or equal to 0.1")
                .max(100, "VAT percentage must be less than or equal to 100"),
            }),
            vat_status: Yup.string().nullable().required('Please select VAT'),
        }),
        onSubmit: (values) => {
            updateVATSettings(values);
        },
        enableReinitialize: true,
    });

    const updateVATSettings = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateFranchiseVATSettings(props?.payload?.id, payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.callbackGetAllFranchiseData()
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error');
        }
    };

    const handleVATCheckbox = async(e, type) => {
        if(type == 'credit'){
            setFieldValue("enabled_student_fee_credit_vat", e.target.checked ? true : false)
        }else {
            setFieldValue("enabled_student_subject_vat", e.target.checked ? true : false)
            if(e.target.checked){
                setFieldValue("vat_status", '')
                setVATTypes([{value: 'inclusive' , name:'Inclusive', isDisabled: false, isChecked: false}, {value: 'exclusive', name:'Exclusive', isDisabled: false, isChecked: false}, {value: 'not_applicable', name:'N/A', isDisabled: true, isChecked: false}])
            }else{
                setFieldValue("vat_status", 'not_applicable')
                setVATTypes([{value: 'inclusive' , name:'Inclusive', isDisabled: true, isChecked: false}, {value: 'exclusive', name:'Exclusive', isDisabled: true, isChecked: false}, {value: 'not_applicable', name:'N/A', isDisabled: false, isChecked: true}])
            }
        }
        if(!(values.enabled_student_subject_vat && values.enabled_student_fee_credit_vat)){
            setFieldValue("standard_vat_percentage", 0)
        }
    }

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Franchise VAT Settings </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <dt>Franchise Name </dt>
                                <dd>{ props?.payload?.name } </dd>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="enabled_student_subject_vat">Student Subject VAT Applicable?</label>
                                <div className="icheck-primary">
                                    <input 
                                        type="checkbox"
                                        id="enabled_student_subject_vat"  
                                        name="enabled_student_subject_vat" 
                                        className="form-check-input"
                                        checked={values.enabled_student_subject_vat} 
                                        onChange={(e) => { handleVATCheckbox(e, 'subject') }}
                                    />
                                    <label htmlFor="enabled_student_subject_vat" className="form-check-label"></label>
                                </div>
                                {(touched.enabled_student_subject_vat && errors.enabled_student_subject_vat) && (
                                    <div className="text-danger">
                                        {errors.enabled_student_subject_vat}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="vat_status">VAT </label>
                                {(vatTypes).map((obj) => (
                                    <div className="form-check col-sm-12" key={obj?.value}>
                                        <input 
                                            className="form-check-input" 
                                            id="vat_status"
                                            type="radio" 
                                            name="vat_status" 
                                            onChange={ handleChange }
                                            value={obj?.value}
                                            checked={obj?.value == values.vat_status}
                                            disabled={obj?.isDisabled}
                                        />
                                        <label className="form-check-label">{ obj?.name }</label><br/>
                                    </div>
                                )) }
                                {(touched.vat_status && errors.vat_status) && (
                                    <div className="text-danger">
                                        {errors.vat_status}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="enabled_student_fee_credit_vat">Student Fee/Credit VAT Applicable?</label>
                                <div className="icheck-primary">
                                    <input 
                                        type="checkbox"
                                        id="enabled_student_fee_credit_vat"  
                                        name="enabled_student_fee_credit_vat" 
                                        className="form-check-input"
                                        checked={values.enabled_student_fee_credit_vat} 
                                        onChange={(e) => { handleVATCheckbox(e, 'credit') }}
                                    />
                                    <label htmlFor="enabled_student_fee_credit_vat" className="form-check-label"></label>
                                </div>
                                {(touched.enabled_student_fee_credit_vat && errors.enabled_student_fee_credit_vat) && (
                                    <div className="text-danger">
                                        {errors.enabled_student_fee_credit_vat}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="standard_vat_percentage">VAT Percentage</label>
                                <div className='input-group'>
                                    <Form.Control 
                                        type="number" 
                                        name="standard_vat_percentage" 
                                        id="standard_vat_percentage" 
                                        placeholder="Enter VAT Percentage" 
                                        value={values.standard_vat_percentage}
                                        onChange={handleChange}
                                        onWheel={ (e) => e.currentTarget.blur() }
                                        disabled={ !(values.enabled_student_fee_credit_vat || values.enabled_student_subject_vat)}
                                    />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">%</span>
                                    </div>
                                </div>
                                {(touched.standard_vat_percentage && errors.standard_vat_percentage) && (
                                    <div className="text-danger">
                                        {errors.standard_vat_percentage}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default UpdateVATSettingsModal;