import React, { useState,useEffect } from 'react';
import {Form} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@app/components';
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddGuardian from '../student-details/AddGuardianModal';
import { getAllActiveFranchise, getPostcodeCountryByFranchiseId } from '@app/services/franchise';
import { toast } from 'react-toastify';
import Select from "react-select";
import { updateStudent,getStudentById, getStudentRollNumberCounter, getStudentSubjectsById, getStudentSubjectHistory, getFeesAndCreditsByStudentWithPagination, deleteFeesCreditById } from './../../services/student';
import { getAllSchools,getAllGrades,createSchoolService } from './../../services/school';
import { getCentresByFranchiseId } from './../../services/centre';
import { getAllActiveGuardianService } from './../../services/guardian';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import { getAllCountries } from '@app/services/postcode';
import CurrencyFormat from 'react-currency-format';
import UpdateStudentSubjectModal from './UpdateStudentSubjectModal';
import StudentSubjectHistoryModal from './StudentSubjectHistoryModal';
import DataTable, { TableStyles } from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert';
import AddStudentFeeCreditModal from './AddStudentFeeCreditModal';
import StudentAggregatedCreditsModal from './StudentAggregatedCreditsModal';

const EditStudent = () => {
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showUSS, setShowUSS] = useState(false);
    const handleCloseUSS = () => setShowUSS(false);
    const [showHistory, setShowHistory] = useState(false);
    const handleHistoryClose = () => setShowHistory(false);
    const [subjectName, setSubjectName] = useState('');
    const [showAddFees, setShowAddFees] = useState(false);
    const handleCloseAddFees = () => setShowAddFees(false);

    const [showAggregated, setShowAggregated] = useState(false);
    const handleAggregatedClose = () => setShowAggregated(false);
    const [aggregatedData, setAggregatedData] = useState([]);

    let { id } = useParams();
    let obj: any = {};
    
    const [subjectPayload, setSubjectPayload] = useState({type: '', student_id: id, subject: {}});
    const [subjectHistory, setSubjectHistory] = useState([]);
    const [historyType, setHistoryType] = useState('');
    const [franchise, setFranchise] = useState([]);
    const [currency, setCurrency] = useState('');
    const [grades,setGrades] = useState([]);
    const [centre,setCentre] = useState([]);
    const [guardian,setGuardian] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [studentData,setStudentData] = useState(obj);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [availableCredits, setAvailableCredits] = useState(0);
    const [outstandingFees, setOutstandingFees] = useState(0);
    const [search, setSearch] = useState({studentId: id, type: '', vatIncluded: '', amount: '', status: ''});
    const [studentCreditFees, setStudentCreditFees] = useState([]);
    const vatTypes = [{value: 'inclusive' , name:'Inclusive'}, {value: 'exclusive', name:'Exclusive'}, {value: 'not_applicable', name:'N/A'}];
    const [invoiceTypes, setInvoiceTypes] = useState([{value: 'monthly' , name:'Monthly', isDisabled: false}, {value: 'on_demand', name:'On Demand', isDisabled: false}])

    /***Creatable options code***/
    interface Option {
        readonly label: string;
        readonly value: string;
      }
      
    const createOption = (label,id:any) => ({
        label,
        value:id
    });

    let defaultSchoolOptions = [ ];
    const [schoolOptions, setSchoolOptions] = useState(defaultSchoolOptions);

    const handleCreateOptions = (inputValue: string) => {
        setLoading(true);
        const postData = {name:inputValue};
        setTimeout(() => {
          setLoading(false);
          createSchool(postData);
        }, 1000);
    };
    
    /***Creatable options code***/

    const setParentBillPayerCheck = event => {
        const val = event.target.checked ? true : false;
        setFieldValue(event.target.name,val);
    };
    
    const navigate = useNavigate();
    const errorSpanStyle = {
        marginLeft:"15px"
    };

    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); }

    const studentStatus = [{id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}];
    const gender = [{id:'male',name:'Male'},{id:'female','name':'Female'},{id:'other','name':'Rather Not Specify'}];
    const [parent1Id,setParent1Id] = useState('');
    const [parent2Id,setParent2Id] = useState('');
    const [countries, setCountries] = useState([]);

    const createSchool = async (payload) => {
        try{
            const createSchoolResp = await createSchoolService(payload);
            if (createSchoolResp.success === true) {
                if(createSchoolResp.collections.length > 0){
                    createSchoolResp.collections.map((school)=>{
                        const newOption = createOption(school.name,school.id);
                        setSchoolOptions((prev) => [...schoolOptions, newOption]);
                        setFieldValue('school_id',school.id);
                    })
                }
            } else {
                toast.error(createSchoolResp?.errors[0]?.msg);
            }
        }catch(error : any){
            toast.error(error?.response?.message || 'Failed');
        }
    }

    useEffect(() => {
        getAllActiveSchoolsData();
        getAllActiveGradesData();
        getStudentDetailsById(id);
        getCountriesData();
        getSubjectsByStudentId(id);
        getCreditFeesByStudentId(1, perPage, sortField, sortDirection)
    },[]);

    useEffect(()=>{
        // setTimeout(() => {
        //     if(countries.length > 0){
        //         let studCountry = countries.find(country => { return country.slug == studentData?.country})
        //         if(studCountry){
        //             setCurrency(studCountry.currency)
        //         }
        //     }
        // }, 2000)
    },[countries, studentData])

    const getStudentDetailsById = async (id) => {
        try {
            const resp = await getStudentById(id);
            if(resp.success === true){
                setStudentData(resp.collections);
                if(resp?.collections?.franchise_id){
                    getAllActiveCentresData(resp?.collections?.franchise_id);
                    getAllActiveFranchiseData(resp?.collections?.franchise_id);
                    getAllActiveGuardianData({franchise_id: [resp?.collections?.franchise_id]});
                    getCountryByFranchiseId(resp?.collections?.franchise_id)
                }
                if(!resp?.collections?.enabled_recurring_invoice)
                    setInvoiceTypes([{value: 'monthly' , name:'Monthly', isDisabled: true}, {value: 'on_demand', name:'On Demand', isDisabled: false}])

                if(resp?.collections?.parents.length > 0){
                    resp?.collections?.parents.map((parent)=>{
                        if(parent.relationship==='parent1'){
                            setParent1Id(parent.id);
                            setFieldValue('parent1_bill_payer',parent.bill_payer);
                        }
                        if(parent.relationship==='parent2'){
                            setParent2Id(parent.id);
                            setFieldValue('parent2_bill_payer',parent.bill_payer);
                        }
                    })
                }
            }else{
                toast.error(resp?.errors[0]?.msg);
                navigate('/students');
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllActiveGuardianData = async (payload) => {
        try {
            const allActiveGuardianResp = await getAllActiveGuardianService(payload);
            if (allActiveGuardianResp.success === true) {
                setGuardian(allActiveGuardianResp.collections);
            } else {
                toast.error(allActiveGuardianResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async (franchise_id) => {
        try {
            const allActiveFranchiseResp = await getAllActiveFranchise();
            if (allActiveFranchiseResp.success === true) {
                setFranchise(allActiveFranchiseResp.collections.filter((obj) => obj?.enabled_manage_students || obj.id == franchise_id))
            } else {
                toast.error(allActiveFranchiseResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllActiveSchoolsData = async () => {
        try{
            const allActiveSchoolsResp = await getAllSchools();
            if (allActiveSchoolsResp.success === true) {
                allActiveSchoolsResp.collections.map((obj)=>{
                    defaultSchoolOptions.push(createOption(obj.name,obj.id));
                });
                setSchoolOptions(defaultSchoolOptions);
            } else {
                toast.error(allActiveSchoolsResp?.errors[0]?.msg);
            }
        }catch (error: any){
            toast.error(error?.message || 'Failed');
        }
    }

    const getAllActiveGradesData = async () => {
        try{
            const allActiveGradesResp = await getAllGrades();
            if (allActiveGradesResp.success === true) {
                setGrades(allActiveGradesResp.collections);
            } else {
                toast.error(allActiveGradesResp?.errors[0]?.msg);
            }
        }catch (error: any){
            toast.error(error?.message || 'Failed');
        }
    }
    
    const getAllActiveCentresData = async (id) => {
        try{
            const allActiveCentresResp = await getCentresByFranchiseId(id);
            if (allActiveCentresResp.success === true) {
                setCentre(allActiveCentresResp.collections);
            } else {
                toast.error(allActiveCentresResp?.errors[0]?.msg);
            }
        }catch (error: any){
            toast.error(error?.message || 'Failed');
        }
    }

    const putStudent = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateStudent(id, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/students');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
          }
    };

    const getCountriesData = async () => {
        try {
            const resp = await getAllCountries();
            if (resp.success === true) {
                setCountries(resp.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            first_name: studentData?.first_name || '',
            last_name: studentData?.last_name || '',
            roll_number: studentData?.roll_number || '',
            gender:studentData?.gender || '',
            grade_id:studentData?.grade_id || '',
            address:studentData?.address || '',
            address_line_2:studentData?.address_line_2 || '',
            city:studentData?.city || '',
            postcode:studentData?.postcode || '',
            country:studentData?.franchise_country_slug || '',
            date_of_birth:studentData?.date_of_birth || '',
            school_id:studentData?.school_id || '',
            franchise_id:studentData?.franchise_id || '',
            centre_id:studentData?.centre_id || '',
            status: studentData?.status || '',
            camp_only: studentData?.camp_only || false,
            invoice_generation_type: studentData?.invoice_generation_type || '',
            parent1: parent1Id || '',
            parent2: parent2Id || '',
            parents:[],
            parent1_bill_payer: studentData?.parent1_bill_payer || '',
            parent2_bill_payer: studentData?.parent2_bill_payer || '',
            parent_msg:'',
            parent_same_msg:''
        },
        validationSchema: Yup.object({
            first_name: Yup.string().trim().required('Please enter first name'),
            last_name: Yup.string().trim().required('Please enter last name'),
            gender: Yup.string().required('Please select gender'),
            address:Yup.string().trim().required('Please enter address line 1'),
            city:Yup.string().trim().required('Please enter city'),
            roll_number:Yup.number().positive('Roll number must be a positive number').max(99999, 'Roll number must be less than or equal to 99999'),
            franchise_id: Yup.string().required('Please select franchise'),
            status: Yup.string().required('Please select status'),
            invoice_generation_type: Yup.string().required('Please select invoice type'),
            parent_msg: Yup.string().when(['parent1','parent2'],{
                is: (parent1,parent2) =>  parent1===undefined && parent2===undefined,
                 then: Yup.string().required('Please select any of the below'),
            }),
            parent_same_msg: Yup.string().when(['parent1','parent2'],{
                is: (parent1,parent2) => ((parent1===parent2) && parent1!==undefined && parent2!==undefined),
                 then: Yup.string().required('Parent1/Parent2 details should not be same'),
            })
        }),
        onSubmit: (values) => {
            //Assigned Parents
            let parentObj = [];
            if(values.parent1){
                parentObj.push({"id":values.parent1,"relationship":"parent1","bill_payer":values.parent1_bill_payer ? values.parent1_bill_payer : false});
            }
            if(values.parent2){
                parentObj.push({"id":values.parent2,"relationship":"parent2","bill_payer":values.parent2_bill_payer ? values.parent2_bill_payer : false});
            }
            values.parents = parentObj;
                
            putStudent(values);
        },
        enableReinitialize: true,
    });

    const getCountryByFranchiseId = async (franchiseId) => {
        try {
            if(franchiseId){
                const resp = await getPostcodeCountryByFranchiseId(franchiseId);
                if (resp?.success === true) {
                    setFieldValue('country', resp?.collections?.country_slug)
                    setCurrency(resp?.collections?.currency)
                } else {
                    toast.error(resp?.errors[0]?.msg);
                }
            }else{
                setFieldValue('country', "")
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getStudentRollNoCounter = async (payload) => {
        try {
            const resp = await getStudentRollNumberCounter(payload);
            if (resp.success === true) {
                setFieldValue('roll_number', resp?.collections?.counter)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	} 
    
    const getSubjectsByStudentId = async (id) => {
        try {
            const resp = await getStudentSubjectsById(id);
            if(resp.success === true){
                setSubjects(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getSubjectHistoryData = async (payload) => {
        try {
            const resp = await getStudentSubjectHistory(payload);
            if(resp.success === true){
                setSubjectHistory(resp.collections);
            }else{
                setSubjectHistory([])
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const handleUpdateSubject = (e, subject, type) => {
        e.preventDefault()
        setShowUSS(true)
        setSubjectPayload({type: type, student_id: id, subject: subject})
    }

    const handleSubjectHistory = (e, subject, type) => {
        e.preventDefault()
        setHistoryType(type)
        setShowHistory(true);
        setSubjectName(subject?.name)
        getSubjectHistoryData({subject_id: subject.id, student_id: id, type: type});
    }

    const getCreditFeesByStudentId = async (page, perPage, sortField, sortDirection) => {
        try {
            const resp = await getFeesAndCreditsByStudentWithPagination(page, perPage, sortField, sortDirection, search);
            if(resp.success === true){
                setLoading(false);
                setStudentCreditFees(resp.collections.data);
                setTotalRows(resp?.collections?.pagination?.total);
                setAvailableCredits(resp?.collections?.pagination?.available_credits)
                setOutstandingFees(resp?.collections?.pagination?.outstanding_fees)
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const columns = [
        {
            name: '#Id',
            selector: (row: { id: string; }) => `#${row.id}`,
            sortable: true,
            sortField: 'id',
            // width: '7%',
            wrap: true
        },
        {
            name: 'Type',
            selector: (row: { type: string; }) => row.type.toUpperCase(),
            sortable: true,
            sortField: 'type',
            // width: '8%',
        },
        // {
        //     name: 'Description',
        //     selector: (row: { description: string; }) => row.description,
        //     sortable: true,
        //     sortField: 'description',
        //     wrap: true
        // },
        {
            name: 'Amount',
            selector: (row: { amount: string; }) => row.amount,
            format: (row: { amount: string }) => <CurrencyFormat value={ parseFloat(row.amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} />,
            sortable: true,
            sortField: 'amount',
        },
        {
            name: 'VAT',
            selector: (row: { vat_status: string; }) => (vatTypes.find(obj => { return obj.value === row.vat_status}))?.name,
            sortable: true,
            sortField: 'vat_status',
            // width: '7%',
            wrap: true
        },
        // {
        //     name: 'Settled Amount',
        //     selector: (row: { invoiced_amount: string; }) => row.invoiced_amount,
        //     format: (row: { invoiced_amount: string }) => row.invoiced_amount !== null ? <CurrencyFormat value={ parseFloat(row.invoiced_amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> : '---',
        //     sortable: true,
        //     sortField: 'total_price',
        // },
        {
            name: 'Status',
            selector: (row: { status: string, aggregated_credits: [];}) => row.status,
            format: (row: { status: string, aggregated_credits: [] }) => (
                row.status !== null ? (row.status === 'active' ? 
                    <span className="badge badge-success">{ row.status.charAt(0).toUpperCase() + row.status.slice(1)}</span> : 
                    <><span className="badge badge-secondary">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) } </span> {row.aggregated_credits.length > 0  ? <a href='#' onClick={(e) => handleAggregatedCredit(e, row.aggregated_credits)}> <i className='fa fa-info-circle' title='View Aggregated Credits'/> </a>: ''} </>) : '---'
            ),
            sortable: true,
            sortField: 'status',
            // width: '10%',
        },
        // {
        //     name: 'Invoice No.',
        //     selector: (row: { invoice_number_id : string; }) => row.invoice_number_id ?? '---',
        //     sortable: true,
        //     sortField: 'invoice_number_id',
        //     // width: '12%',
        //     wrap: true
        // },
        {
            name: 'Created On',
            selector: (row: { created_at: string; }) => row.created_at,
            cell: (row: { created_at: string; }) => (
                row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'created_at',
            // width: '10%',
        },
        // {
        //     name: 'Settled On',
        //     selector: (row: { invoiced_at: string; }) => row.invoiced_at,
        //     cell: (row: { invoiced_at: string; }) => (
        //         row.invoiced_at ? moment(row.invoiced_at).format('DD/MM/YYYY') : '---'
        //     ),
        //     sortable: true,
        //     sortField: 'invoiced_at',
        //     // width: '10%',
        //     wrap: true
        // },
        {  
            name: 'Action',
            cell: (row: { id: number; status: string;}) => (
              <>
                { row.status == 'active' &&
                    <a href='#' onClick={ (e) => { deleteCreditFeeConfirm(e, row.id)} } data-toggle="tooltip" title="Delete">
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
              </>
            ),
        },
    ];

    const ExpandedComponent = ({ data }) => 
        <div className='border border-secondary rounded'>
            <div className="row m-2" style={{fontSize: '14px'}}>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Description </label>
                        <dd>{ data?.description ? data?.description : '---' }</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Invoiced Amount </label>
                        <dd>{ data?.invoiced_total_amount !== null ? <CurrencyFormat value={ parseFloat(data?.invoiced_total_amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> : '---'}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Invoiced On </label>
                        <dd>{ data?.invoiced_at ? moment(data?.invoiced_at).format('DD/MM/YYYY') : '---' }</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Invoice ID </label>
                        <dd>{ data?.student_invoice_id ? data?.student_invoice_id : '---' }</dd>
                    </div>
                </div>
            </div>
        </div>;

    const handleAggregatedCredit = (e, aggregated_credits) => {
        e.preventDefault()
        setShowAggregated(true)
        setAggregatedData(aggregated_credits)
    }

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const deleteCreditFeeConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteCreditFee(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteCreditFee = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteFeesCreditById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getCreditFeesByStudentId(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

    const handlePageChange = page => {
        getCreditFeesByStudentId(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getCreditFeesByStudentId(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getCreditFeesByStudentId(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleAddFeeCredits = (e) => {
        e.preventDefault()
        setShowAddFees(true)
    }

    const callbackGetCreditFeesByStudentId = () => {
        getCreditFeesByStudentId(1, perPage, sortField, sortDirection)
    }

    const callbackGetAllActiveGuardianData = () => {
        getAllActiveGuardianData({franchise_id: values.franchise_id == '' ? [] : [values.franchise_id] });
    }

    return (
        <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary" key={1}>
                            <div className="card-header">
                                <h3 className="card-title">Edit Student</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputFirstName">First Name <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputFirstName" 
                                                    placeholder="Enter first name"
                                                    name="first_name" 
                                                    onChange={handleChange} 
                                                    value={values.first_name}
                                                />
                                                {(touched.first_name && errors.first_name) && (
                                                    <div className="text-danger">
                                                        {errors.first_name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputLastName">Last Name <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputLastName" 
                                                    placeholder="Enter last name"
                                                    name="last_name" 
                                                    onChange={handleChange} 
                                                    value={values.last_name}
                                                />
                                                {(touched.last_name && errors.last_name) && (
                                                    <div className="text-danger">
                                                        {errors.last_name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Date Of Birth</label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="date_of_birth" 
                                                    id="date_of_birth" 
                                                    placeholder="Enter date of birth" 
                                                    value={moment(values.date_of_birth).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.date_of_birth && errors.date_of_birth) && (
                                                    <div className="text-danger">
                                                        {errors.date_of_birth}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputGender">Gender <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="gender"
                                                    onChange={handleChange} 
                                                    value={values.gender}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {gender.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.gender && errors.gender) && (
                                                    <div className="text-danger">
                                                        {errors.gender}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputGrade">School Year</label>
                                                <select
                                                    className="form-control"
                                                    name="grade_id"
                                                    onChange={handleChange} 
                                                    value={values.grade_id}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {grades.map((obj) => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                    ))}
                                                </select>
                                                {(touched.grade_id && errors.grade_id) && (
                                                    <div className="text-danger">
                                                        {errors.grade_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">  
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputGrade">School &nbsp;
                                                        <i className='fa fa-info-circle' title='You can create new school by entering school name if it is not already present in list.'/>
                                                    </label>
                                                    <CreatableSelect
                                                        isClearable
                                                        isDisabled={isLoading}
                                                        isLoading={isLoading}
                                                        onChange={ (option) => { 
                                                            if(option){
                                                                setFieldValue("school_id", option.value)
                                                            }else{
                                                                setFieldValue("school_id", '')
                                                            }
                                                        }}
                                                        onCreateOption={handleCreateOptions}
                                                        options={schoolOptions}
                                                        value={schoolOptions.filter(function(option) {
                                                            return option.value === values.school_id;
                                                        })}
                                                        name="school_id"
                                                    />
                                                    {(touched.school_id && errors.school_id) && (
                                                        <div className="text-danger">
                                                            {errors.school_id}
                                                        </div>
                                                    )}
                                                </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputGrade">Franchise <span className="required">*</span></label>
                                                <Select
                                                    name="franchise_id"
                                                    placeholder="Select Franchise"
                                                    options={franchise}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => { 
                                                        if(option){
                                                            setFieldValue("franchise_id", option.id) 
                                                            getAllActiveCentresData(option.id)
                                                            getCountryByFranchiseId(option.id)
                                                            getStudentRollNoCounter({student_id: id, franchise_id: option.id, centre_id: values.centre_id})
                                                            getAllActiveGuardianData({franchise_id: [option.id]})
                                                        }else{
                                                            setFieldValue("franchise_id", '')
                                                            getAllActiveGuardianData({franchise_id: []})
                                                        }
                                                    }}
                                                    isClearable={true}
                                                    value={franchise.filter(function(option) {
                                                        return option.id === values.franchise_id;
                                                    })}
                                                    isDisabled
                                                />
                                                {(touched.franchise_id && errors.franchise_id) && (
                                                    <div className="text-danger">
                                                        {errors.franchise_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputGrade">Centre</label>
                                                <select
                                                    className="form-control"
                                                    name="centre_id"
                                                    onChange={ (e) => { 
                                                        setFieldValue("centre_id", e.target.value)
                                                        getStudentRollNoCounter({student_id: id, franchise_id: values.franchise_id, centre_id: e.target.value})
                                                    }}
                                                    value={values.centre_id}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {centre.map((obj) => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                </select>
                                                {(touched.centre_id && errors.centre_id) && (
                                                    <div className="text-danger">
                                                        {errors.centre_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputRollNo">Roll No.</label>
                                                <Form.Control 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputRollNo" 
                                                    placeholder="Enter roll no."
                                                    name="roll_number" 
                                                    onChange={handleChange} 
                                                    value={values.roll_number}
                                                    onWheel={ (e) => OnWheelPreventChange(e) }
                                                />
                                                {(touched.roll_number && errors.roll_number) && (
                                                    <div className="text-danger">
                                                        {errors.roll_number}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="invoice_generation_type">Invoice Type <span className="required">*</span></label>
                                                {(invoiceTypes).map((obj) => (
                                                    <div className="form-check col-sm-12" key={obj?.value}>
                                                        <input 
                                                            className="form-check-input" 
                                                            id="invoice_generation_type"
                                                            type="radio" 
                                                            name="invoice_generation_type" 
                                                            onChange={ handleChange }
                                                            value={obj?.value}
                                                            checked={values.invoice_generation_type == obj?.value}
                                                            disabled={obj?.isDisabled }
                                                        />
                                                        <label className="form-check-label">{ obj?.name }</label><br/>
                                                    </div>
                                                )) }
                                                {(touched.invoice_generation_type && errors.invoice_generation_type) && (
                                                    <div className="text-danger">
                                                        {errors.invoice_generation_type}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputAddress">Address Line 1<span className="required">*</span></label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputAddress" 
                                                    placeholder="Enter address line 1" 
                                                    name="address" 
                                                    onChange={handleChange} 
                                                    value={values.address}
                                                ></textarea>
                                                {(touched.address && errors.address) && (
                                                    <div className="text-danger">
                                                        {errors.address}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputAddressLine2">Address Line 2</label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputAddressLine2" 
                                                    placeholder="Enter address line 2" 
                                                    name="address_line_2" 
                                                    onChange={handleChange} 
                                                    value={values.address_line_2}
                                                ></textarea>
                                                {(touched.address_line_2 && errors.address_line_2) && (
                                                    <div className="text-danger">
                                                        {errors.address_line_2}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputCity">City <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputCity" 
                                                    placeholder="Enter city"
                                                    name="city" 
                                                    onChange={handleChange} 
                                                    value={values.city}
                                                />
                                                {(touched.city && errors.city) && (
                                                    <div className="text-danger">
                                                        {errors.city}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPostcode">Postcode </label>
                                                <Form.Control 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="exampleInputPostcode" 
                                                    placeholder="Enter postcode"
                                                    name="postcode" 
                                                    onChange={handleChange} 
                                                    value={values.postcode}
                                                />
                                                {(touched.postcode && errors.postcode) && (
                                                    <div className="text-danger">
                                                        {errors.postcode}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputCountry">Country </label>
                                                <select
                                                    id="exampleInputCountry" 
                                                    className="form-control"
                                                    name="country"
                                                    onChange={ (e) => {
                                                        handleChange(e);
                                                        if(e.target.value != ''){
                                                            setCurrency((countries.find(obj => {return obj.slug == e.target.value}))?.currency)
                                                        }else{
                                                            setCurrency('')
                                                        }
                                                    }} 
                                                    value={values.country}
                                                    disabled
                                                >
                                                    <option value="">--Please select--</option>
                                                    {countries.map((obj) => (
                                                        <option key={obj.slug} value={obj.slug}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.country && errors.country) && (
                                                    <div className="text-danger">
                                                        {errors.country}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputStatus">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={handleChange} 
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {studentStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="icheck-primary">
                                                <input 
                                                    type="checkbox" 
                                                    className="form-check-input" 
                                                    checked={values.camp_only}
                                                    id="camp_only"
                                                    name="camp_only"
                                                    onChange={ handleChange }
                                                />
                                                <label htmlFor="camp_only" className="form-check-label">
                                                    <b>Holiday Camp Only</b>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className='row'>
                                        <div className="col-sm-8 pl-4">
                                            <h3 className="card-title">Parent/Guardian Details</h3>
                                            <span style={errorSpanStyle}>
                                                {((touched.parent1 || touched.parent2) && errors.parent_msg) && (
                                                    <div className="text-danger">
                                                        {errors.parent_msg}
                                                    </div>
                                                )}
                                                {((touched.parent1 || touched.parent2) && errors.parent_same_msg) && (
                                                    <div className="text-danger">
                                                        {errors.parent_same_msg}
                                                    </div>
                                                )}
                                            </span>
                                            <div className='row'>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputGrade">Select Parent1 Details <span className="required">*</span></label>
                                                        <Select
                                                            name="parent1"
                                                            placeholder="Select parent1"
                                                            options={guardian}
                                                            getOptionValue={(option)=>`${option['id']}`}
                                                            getOptionLabel={(option)=>`${option['first_name'] +' '+option['last_name'] + ' ( ' +option['email']+' )'}`}
                                                            onChange={ (option) => { 
                                                                if(option){
                                                                    setFieldValue("parent1", option.id) 
                                                                }else{
                                                                    setFieldValue("parent1", '')
                                                                }
                                                            }}
                                                            isClearable={true}
                                                            value={guardian.filter(function(option) {
                                                                if(option.id === values.parent1){
                                                                    return option.id;
                                                                }
                                                            })}
                                                        />
                                                        {(touched.parent1 && errors.parent1) && (
                                                            <div className="text-danger">
                                                                {errors.parent1}
                                                            </div>
                                                        )}
                                                        <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input 
                                                                type="checkbox" 
                                                                className="form-check-input" 
                                                                checked = {values.parent1_bill_payer ? true : false}
                                                                value={values.parent1_bill_payer}
                                                                name="parent1_bill_payer"
                                                                onChange={setParentBillPayerCheck}
                                                            />
                                                            <b>Bill Payer</b>
                                                        </label>
                                                    </div>
                                                    </div>
                                                    
                                                    <a href='#' onClick={() =>handleShow()}> + Add Parent Details </a>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputGrade">Select Parent2 Details <span className="required">*</span></label>
                                                        <Select
                                                            name="parent2"
                                                            placeholder="Select parent2"
                                                            options={guardian}
                                                            getOptionValue={(option)=>`${option['id']}`}
                                                            getOptionLabel={(option)=>`${option['first_name'] +' '+option['last_name'] + ' ( ' +option['email']+' )'}`}
                                                            onChange={ (option) => { 
                                                                if(option){
                                                                    setFieldValue("parent2", option.id) 
                                                                }else{
                                                                    setFieldValue("parent2", '')
                                                                }
                                                            }}
                                                            isClearable={true}
                                                            value={guardian.filter(function(option) {
                                                                if(option.id === values.parent2){
                                                                    return option.id;
                                                                }
                                                            })}
                                                        />
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="form-check-input" 
                                                                    checked = {values.parent2_bill_payer ? true : false}
                                                                    value={values.parent2_bill_payer}
                                                                    name="parent2_bill_payer"
                                                                    onChange={setParentBillPayerCheck}
                                                                />
                                                                <b>Bill Payer</b>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <AddGuardian show={show} handleClose={handleClose} franchise={franchise} countries={countries} callbackGetAllActiveGuardianData={callbackGetAllActiveGuardianData} />
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                    <Link className="btn btn-default" to='/students'>Cancel</Link>
                                </div>
                            </form>
                        </div>

                        <div className="card card-primary" key={2}>
                            <div className="card-header">
                                <h3 className="card-title">Manage Student Subjects</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                        {subjects?.length > 0 && (
                                            <>
                                            <div className="col-md-12">
                                                <table className="table table-bordered" key='manage-student-subject'>
                                                    <tbody>
                                                        <tr className='text-center' key='heading'>
                                                            <th>Subject</th>
                                                            <th>Rate Per Month</th>
                                                            {/* <th>First Invoice Amount</th> */}
                                                            <th>VAT</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        {subjects?.length > 0  && subjects.map((subject) => (
                                                            <tr key={subject?.id}>
                                                                <td>{ subject.name }</td>
                                                                <td>
                                                                    { subject?.rate ? <CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> : '---' }
                                                                </td>
                                                                {/* <td>
                                                                    { subject?.first_invoice_rate ? <CurrencyFormat value={ subject?.first_invoice_rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> : '---' }
                                                                </td> */}
                                                                <td>
                                                                    { subject.vat_status ? (vatTypes.find(obj => { return obj.value === subject.vat_status}))?.name : '---'}
                                                                </td>
                                                                <td>{ (subject.start_date != null) ? moment(subject.start_date).format('DD-MM-YYYY') : '---'}</td>
                                                                <td>{ (subject.end_date != null) ? moment(subject.end_date).format('DD-MM-YYYY') : '---'}</td>
                                                                <td>    { subject.status === 'active' ? <span className="badge bg-success">Active</span> 
                                                                        : (subject.status === 'inactive' ? <span className="capitalize badge bg-danger">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span> : <span className="capitalize badge bg-warning">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span>)  }
                                                                </td>
                                                                <td>
                                                                    { !studentData?.camp_only && studentData?.status == 'active' && (
                                                                        <>
                                                                            <a href="#" onClick={ (e) => handleUpdateSubject(e, subject, 'subject') } data-toggle="tooltip" title="Activate/Inactivate Subject"> <i className="fas fa-pen mr-2" /> </a> 
                                                                            {(subject.status === 'active' || subject.status === 'upcoming') && (
                                                                                <a href="#" onClick={ (e) => handleUpdateSubject(e, subject, 'rate') } data-toggle="tooltip" title="Edit Subject Rate"> <i className="fas fa-money-bill-alt mr-2" /> </a> 
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    <a href="#" onClick={ (e) => handleSubjectHistory(e, subject, 'rate') } data-toggle="tooltip" title="Subject History"> <i className="fas fa-history mr-2" /> </a> 
                                                                    <a href="#" onClick={ (e) => handleSubjectHistory(e, subject, 'first-invoice-rate') } data-toggle="tooltip" title="First Invoice History"> <i className="fas fa-file-invoice-dollar mr-2"/> </a> 
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                { studentData?.status != 'active' && (
                                                    <p className='text-danger'><b>Note:</b> The student subjects information cannot be updated while the student is inactive.</p>
                                                )}
                                            </div> 
                                            </>
                                        )}
                                        {subjects?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                </div>
                            </div>
                        </div>

                        <div className="card card-primary" key={3}>
                            <div className="card-header">
                                <h3 className="card-title">Manage Student Fees/Credit</h3>
                                <div className="text-right">
                                    <a className='font-weight-bold' href='#' onClick={ (e) => handleAddFeeCredits(e)}><i className="fas fa-plus"/> Add Fee/Credit </a>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className='info-box bg-light'>
                                            <div className="info-box-content">
                                                <span className='info-box-text text-center text-muted'>Student Name </span>
                                                <span className='info-box-number text-center text-muted mb-0'>{studentData?.first_name+' '+studentData?.last_name} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className='info-box bg-light'>
                                            <div className="info-box-content">
                                                <span className='info-box-text text-center text-muted'>Outstanding Fees </span>
                                                <span className='info-box-number text-center text-muted mb-0'>{ <CurrencyFormat value={outstandingFees} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> } </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className='info-box bg-light'>
                                            <div className="info-box-content">
                                                <span className='info-box-text text-center text-muted'>Available Credits </span>
                                                <span className='info-box-number text-center text-muted mb-0'>{ <CurrencyFormat value={availableCredits} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> } </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                        {subjects?.length > 0 && (
                                            <>
                                            <div className="col-md-12">
                                                <DataTable
                                                    columns={columns}
                                                    data={studentCreditFees}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationDefaultPage={currentPage}
                                                    onChangePage={handlePageChange}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    paginationPerPage={perPage}
                                                    paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                                    progressPending={isLoading}
                                                    sortServer
                                                    onSort={handleSort}
                                                    customStyles={customStyle}
                                                    highlightOnHover
                                                    expandableRows
                                                    expandableRowsComponent={ExpandedComponent}
                                                />
                                            </div> 
                                            </>
                                        )}
                                        {subjects?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateStudentSubjectModal
                show={showUSS}
                handleClose={handleCloseUSS}
                payload={subjectPayload}
                studentId={id}
                subjectVAT={studentData?.enabled_student_subject_vat}
                defaultVatStatus={studentData?.default_vat_status}
                currency={currency}
                getSubjectsByStudentId={getSubjectsByStudentId}
            />

            <StudentSubjectHistoryModal
                show={showHistory}
                handleClose={handleHistoryClose}
                subjectName={subjectName}
                historyType={historyType}
                historyData={subjectHistory}
                currency={currency}
            />

            <AddStudentFeeCreditModal
                show={showAddFees}
                handleClose={handleCloseAddFees}
                student={studentData}
                feeCreditVAT={studentData?.enabled_student_fee_credit_vat}
                currency={currency}
                callbackGetCreditFeesByStudentId={callbackGetCreditFeesByStudentId}
            />

            <StudentAggregatedCreditsModal
                show={showAggregated}
                handleClose={handleAggregatedClose}
                aggregatedData={aggregatedData}
                currency={currency}
            />
        </section>
    </div>
    );
}

export default EditStudent;