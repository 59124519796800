import { Modal } from 'react-bootstrap';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

const SubjectHistoryModal = (props) => {

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.subjectName} History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    {props.type === 'subject' && (
                        <div className="col-md-12">
                            {(props.historyData)?.length > 0 && (
                                <table className="table table-bordered" id="client_id_table">
                                    <tbody>
                                        <tr className='text-center'>
                                            <th>Status</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                        </tr>
                                        {(props.historyData)?.length > 0  && (props.historyData).map((subject, i) => (
                                            <tr key={subject.i} className='text-center'>
                                                <td>{ subject.status === 'active' ? 
                                                    <span className="badge bg-success">Active</span> 
                                                    : <span className="capitalize badge bg-danger">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span> }
                                                </td>
                                                <td>{ (subject.start_date != null) ? moment(subject.start_date).format('DD/MM/YYYY') : 'N/A'}</td>
                                                <td>{ (subject.end_date != null) ? moment(subject.end_date).format('DD/MM/YYYY') : 'N/A'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            {(props.historyData)?.length == 0 && (
                                <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                            )}
                        </div>
                    )}

                    {props.type === 'rate' && (
                        <div className="col-md-12">
                            {(props.rateHistoryData)?.length > 0 && (
                                <table className="table table-bordered" id="client_id_table">
                                    <tbody>
                                        <tr className='text-center'>
                                            <th>Rate</th>
                                            <th>Is Default</th>
                                            <th>Created Date</th>
                                            {/* <th>Updated Date</th> */}
                                        </tr>
                                        {(props.rateHistoryData)?.length > 0  && (props.rateHistoryData).map((subject, i) => (
                                            <tr key={subject.i} className='text-center'>
                                                <td><CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> </td>
                                                <td>{ subject.is_default ? 'Yes' : 'No' } </td>
                                                <td>{ (subject.created_at != null) ? moment(subject.created_at).format('DD/MM/YYYY HH:mm') : 'N/A'}</td>
                                                {/* <td>{ (subject.updated_at != null) ? moment(subject.updated_at).format('DD/MM/YYYY HH:mm') : 'N/A'}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            {(props.rateHistoryData)?.length == 0 && (
                                <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                            )}
                        </div>
                    )}

                    {props.type === 'default-rate' && (
                        <div className="col-md-12">
                            {(props.subDefaultRateHistory)?.length > 0 && (
                                <table className="table table-bordered" id="client_id_table">
                                    <tbody>
                                        <tr className='text-center'>
                                            <th>Rate</th>
                                            <th>Created Date</th>
                                            <th>Updated Date</th>
                                        </tr>
                                        {(props.subDefaultRateHistory)?.length > 0  && (props.subDefaultRateHistory).map((subject, i) => (
                                            <tr key={subject.i} className='text-center'>
                                                <td><CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={ props?.currency + ' ' } /> </td>
                                                <td>{ (subject.created_at != null) ? moment(subject.created_at).format('DD/MM/YYYY HH:mm') : 'N/A'}</td>
                                                <td>{ (subject.updated_at != null) ? moment(subject.updated_at).format('DD/MM/YYYY HH:mm') : 'N/A'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            {(props.subDefaultRateHistory)?.length == 0 && (
                                <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                            )}
                        </div>
                    )}
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    </div>
  )
}

export default SubjectHistoryModal;