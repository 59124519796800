import { createSlice, current } from '@reduxjs/toolkit';

export const subjectSlice = createSlice({
	name: 'subject',
	initialState: {
		data: [],
	},
	reducers: {
		setActiveSubjects(state, action) {
			state.data = action.payload;
		},
		setSubjectCheck: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].active = payload.active;
			if(!payload.active){
				state.data[objIndex].is_default = false;
				state.data[objIndex].rate = '';
				state.data[objIndex].start_date = '';
				state.data[objIndex].first_invoice_rate = '';
			}else{
				state.data[objIndex].rate = state.data[objIndex].student_default_rate ?? '';
				state.data[objIndex].first_invoice_rate = state.data[objIndex].student_default_rate ?? '';
				state.data[objIndex].error = state.data[objIndex].student_default_rate ? '' : 'Please enter rate';
			}
			return;
		},
		setDefaultCheck: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].is_default = payload.is_default;
			return;
		},
		setSubjectRate: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].rate = payload.rate;
			return;
		},
		setSubjectRateError: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].error = payload.error;
			return;
		},
		setFirstInvoiceRate: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].first_invoice_rate = payload.first_invoice_rate;
			return;
		},
		setFirstInvoiceRateError: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].firstInvoiceRateError = payload.firstInvoiceRateError;
			return;
		},
		setSubjectStartDate: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].start_date = payload.start_date;
			return;
		},
		setSubjectStartDateError: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].startDateError = payload.startDateError;
			return;
		},
		setSubjectRateVAT: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].vat_status = payload.vat_status;
			return;
		},
		setSubjectRateVATError: (state, { payload }) => {
			const objIndex  = state.data.findIndex( e => e.slug === payload.slug )
			state.data[objIndex].vatStatusError = payload.vatStatusError;
			state.data[objIndex].vat_status = '';
			return;
		},
	}
});

export const { setActiveSubjects, setSubjectCheck, setDefaultCheck, setSubjectRate, setSubjectRateError, setSubjectStartDate, setSubjectStartDateError, setSubjectRateVAT, setSubjectRateVATError, setFirstInvoiceRate, setFirstInvoiceRateError } = subjectSlice.actions;

export default subjectSlice.reducer;
